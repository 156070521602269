<template>
  <div class="agrntBox"
       style="height:470px;margin-top: 40px;">
    <!-- <div class="title">
            -专业的服务-
        </div> -->
    <div class="textImgBox">

      <div class="rightImg">
        <div class="bottomImg">
          <img src="@/static/img/laboratory/2-1.png"
               alt="">
          <div class="topImg">
            <img src="@/static/img/laboratory/2-2.png"
                 alt="">
          </div>
        </div>
      </div>
      <div class="leftTextBox">
        <div class="textTitle">
          数据安全
        </div>
        <div class="textContent">
          企业级别安全打造的完整云上安全体系、全方<br>位保障结算数据安全
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "laboratoryData",
  data () {
    return {
    }
  },
  methods: {
  }
}
</script>
<style  scoped>
.agrntBox {
  display: flex;
  flex-direction: column;
}
.leftTextBox {
  width: 403px;
  margin-top: 155px;
  margin-left: 60px;
}
.title {
  font-size: 30px;
  font-weight: 500;
  color: #333333;
  margin-top: 70px;
  margin-bottom: 50px;
  text-align: center;
}
.textImgBox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 1240px;
  margin: 0 auto;
}
.textTitle {
  position: relative;
  font-size: 30px;
  font-weight: 500;
  color: #333333;
}
.textTitle::before {
  content: "";
  position: absolute;
  width: 40px;
  height: 3px;
  z-index: 1000;
  bottom: -20px;
  background-color: #df2a29;
  border-radius: 2px;
}
.textContent {
  width: 403px;
  margin-top: 33px;
  font-size: 20px;
  font-weight: 400;
  color: #333333;
  line-height: 30px;
}
.bottomImg {
  width: 717px;
  height: 430px;
  position: relative;
}
.bottomImg img {
  width: 612px;
  height: 100%;
  box-shadow: 5px 5px 16px 0px rgba(0, 0, 0, 0.12);
  border-radius: 12px;
}
.topImg {
  position: absolute;
  width: 350px;
  height: 210px;
  top: 200px;
  left: 367px;
}
.topImg img {
  box-shadow: 5px 5px 11px 0px rgba(0, 0, 0, 0.12);
  width: 100%;
  height: 100%;
}
</style>
<style  scoped>
@media screen and (max-width: 1240px) {
  .agrntBox {
    min-width: 1240px;
  }
  .textImgBox {
    min-width: 1240px;
  }
}
</style>