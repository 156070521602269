import { render, staticRenderFns } from "./laboratoryFoot.vue?vue&type=template&id=f92e1410&scoped=true&"
import script from "./laboratoryFoot.vue?vue&type=script&lang=js&"
export * from "./laboratoryFoot.vue?vue&type=script&lang=js&"
import style0 from "./laboratoryFoot.vue?vue&type=style&index=0&id=f92e1410&lang=scss&scoped=true&"
import style1 from "./laboratoryFoot.vue?vue&type=style&index=1&id=f92e1410&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.9.6@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f92e1410",
  null
  
)

export default component.exports