<template>
  <div class="wrapper">
    <div class="info">
      <p><a href="http://www.beian.miit.gov.cn/"> 豫ICP备 19039737号</a> 中能魔力（河南）网络科技有限公司</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "laboratoryFoot"
}
</script>

<style lang="scss" scoped>
.info {
  font-size: 11px;
  background-color: #f9f9f9;
  height: 80px;
  bottom: 0.1rem;
  width: 100%;
  line-height: 80px;

  p {
    text-align: center;
    color: #666666;

    .police {
      font-size: 0.4rem;
    }
  }
}

a {
  color: #666666;
  font-size: 11px;
}
</style>
<style  scoped>
@media screen and (max-width: 1240px) {
  .wrapper {
    min-width: 1240px;
  }
}
</style>