<template>
  <div class="wrapper">
    <laboraHeader></laboraHeader>
    <laboratorySystem></laboratorySystem>
    <laboratoryAgent></laboratoryAgent>
    <laboratoryData></laboratoryData>
    <laboratoryCloud></laboratoryCloud>
    <laboratoryManage></laboratoryManage>
    <laboratoryCourse></laboratoryCourse>
    <laboratoryFoot></laboratoryFoot>
    <!-- <LXContent></LXContent> -->
  </div>
</template>

<script>
import laboraHeader from './laboraHeader'
import laboratorySystem from './laboratorySystem'
import laboratoryAgent from './laboratoryAgent'
import laboratoryData from './laboratoryData'
import laboratoryCloud from './laboratoryCloud'
import laboratoryManage from './laboratoryManage'
import laboratoryCourse from './laboratoryCourse'
import laboratoryFoot from './laboratoryFoot'
// import LXContent from './content'
export default {
  name: "laboratory",
  data () {
    return {
    }
  },
  components: {
    laboraHeader,
    laboratorySystem,
    laboratoryAgent,
    laboratoryData,
    laboratoryCloud,
    laboratoryManage,
    laboratoryCourse,
    laboratoryFoot
  },
  methods: {

  }
}
</script>

<style scoped>
.wrapper {
  overflow: hidden;
}
@media screen and (max-width: 1240px) {
  .wrapper {
    min-width: 1240px;
    overflow-x: auto;
  }
}
</style>